/* You can add global styles to this file, and also import other style files */
@import './assets/style/bootstrap5.2.3.min.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './assets/style/global_colors.scss';
@import './assets/style/global_classes.scss';

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 25px;
}
html {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 35%;
  width: 40px;
  height: 40px;
  border: 1px solid $black;
  border-radius: 50%;
  background: #FFFFFF99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in;
}
.owl-theme .owl-nav .owl-prev:hover{
  background: $main_color;
}
.owl-theme .owl-nav .owl-next:hover {
  background: $main_color;
}
.owl-theme .owl-nav .owl-prev {
  left: 5px;
}
.owl-theme .owl-nav .owl-next {
  right: 5px;
}

.owl-theme .owl-dots .owl-dot span {
  background: $white;
  width: 66px;
  height: 6px;
  border-radius: 2px;
  border: none;
  display: none;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: $main_color;
}
.owl-carousel img {
  width: 12px;
  height: 20px;
}
@media screen and (max-width: 800px) {
  .owl-theme .owl-nav .owl-prev,
  .owl-theme .owl-nav .owl-next {
    display: none;
  }

  .owl-theme .owl-dots .owl-dot span {
    margin: 0;
    width: 30px;
    margin-top: -20px;
  }
  .owl-item {
    margin-right: 0;
    width: 120px;
  }
}

.ng-select.ng-select-single,
.ng-select-container {
  border-radius: 8px !important;
}

.global-loader {
  display: block;
  margin-top: 5px;
  width: 4rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
  radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
  conic-gradient(#0000 30%,#ffa516);
-webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);

  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

.toast-container {
  z-index: 999999999999 !important;
}