@import './global_colors.scss';

.main_button {
    background: $main_color;
    border-radius: 14px;
    border: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.01em;
    color: $black;
}
// .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//   max-width: 1515px;
// }

.grey_button {
    background: $dark_grey;
    border-radius: 14px;
    border: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $white;
}

.social-media-button {
  border: none;
  border-radius: 16px;
  padding: 0 50px;
  gap: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 49px;
}

.main_input {
    background: $white;
    border: 1px solid #D5D5D5;
    padding: 8px 10px;
    border-radius: 8px;
}

.grey_input {
    border: 1px solid;
    padding: 8px 10px;
    border-radius: 8px;
}

.main_textarea {
    background: $white;
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    padding: 10px;
}

.grey_textarea {
    background: $light_grey;
    border: none;
    border-radius: 10px;
    padding: 10px;
}

.account-button,
.account-button-right {
    padding: 9px 10px 10px 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    background: $white;
    border-radius: 15px;
    max-height: 30px;
    margin-bottom: 10px;
    text-decoration: none;
    padding-top: 25px;

}

.account-button:hover {

    background: $main_color;
}

.active-account-button {

     background: $main_color;
}

.account-right-arrow {
    display: none;
}

.account-button:hover .account-right-arrow,
.active-account-button .account-right-arrow {
    display: inline-block;
    margin-left: auto;
}

.account-button-text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: $black;
    margin-bottom: 15px;
}

.outside-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000066;
  z-index: 99;
  height: 100vh;
  transition: right 0s ease-out 0s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 767px) {
  .table-responsive {
    overflow-x: scroll;
  }
}
